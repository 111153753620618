import React, { useCallback, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { MATCH_STATUS } from 'libs/constants';
import getMatchInfo, { getMatchScores } from 'libs/api/Match';
import {
  head,
  isEmpty,
  formatDate,
  isTodayDate,
  size as sizeFunc,
  isNull,
} from 'theme/utils/functions';

import Text from 'components/foundation/Text';

import Team from './Team';

import * as S from './styles';

const shieldPlaceholder = '/images/logos/shield-team-placeholder.svg';

const Info = ({ date, matchData = {} } = {}) => {
  const { time, status, score, round, competitionName } = matchData;
  const formattedTime = formatDate({ date, format: 'HH:mm' });
  const isToday = isTodayDate({ date });
  const formattedDate = isToday
    ? 'HOJE'
    : formatDate({
        date,
        format: 'DD/MM/YYYY',
      });

  return (
    <S.Info>
      {status.isLive && !isEmpty(time) ? (
        <Text
          as='time'
          $csscolor='500'
          dateTime={date}
          className='info__date'
          {...(isEmpty(date)
            ? {}
            : {
                title: formatDate({
                  date,
                  format: 'DD [de] MMMM [de] YYYY [às] HH:mm:ss',
                }),
              })}
        >
          {time}
        </Text>
      ) : (
        <Text
          as='time'
          $csscolor='500'
          dateTime={date}
          className='info__date'
          {...(isEmpty(date)
            ? {}
            : {
                title: formatDate({
                  date,
                  format: 'DD [de] MMMM [de] YYYY [às] HH:mm',
                }),
              })}
        >
          {formattedDate}
        </Text>
      )}

      {status.isNotStarted ? (
        <Text
          as='time'
          $csscolor='900'
          dateTime={date}
          className='info__time'
          {...(isEmpty(date)
            ? {}
            : {
                title: formatDate({
                  date,
                  format: 'DD [de] MMMM [de] YYYY [às] HH:mm',
                }),
              })}
        >
          {formattedTime}
        </Text>
      ) : null}

      {status.isEnded || status.isLive ? (
        <>
          <Text
            as='strong'
            className='info__score'
            $csscolor={status.isLive ? 'actions.live' : '900'}
            $cssFontWeight={status.isLive ? { xs: 'bold' } : { xs: 'normal' }}
          >
            {score?.home?.match} - {score?.away?.match}
          </Text>

          {isNull(score?.home?.penalties) ||
          isNull(score?.away?.penalties) ? null : (
            <Text
              as='span'
              className='info__score--penalties'
              $csscolor={status.isLive ? 'actions.live' : '900'}
            >
              ({score?.home?.penalties}) penalidades ({score?.away?.penalties})
            </Text>
          )}
        </>
      ) : null}

      <Text
        as='span'
        $csscolor='900'
        $cssTextAlign='center'
        className='info__competition'
      >
        {competitionName}
      </Text>

      {isEmpty(round) ? null : (
        <Text
          as='span'
          $csscolor='500'
          $cssTextAlign='center'
          className='info__location'
        >
          {round}
        </Text>
      )}
    </S.Info>
  );
};

Info.propTypes = {
  time: PropTypes.string,
  rawDate: PropTypes.string,
  location: PropTypes.string,
  matchData: PropTypes.shape({}),
  date: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'large']),
};

const HeadToHeadCard = ({
  data,
  infos,
  match,
  isMobile,
  size = 'small',
  enableFixed = false,
}) => {
  const [isFixed, setIsFixed] = useState(false);
  const cardRef = useRef(null);
  const firstOdd = head(data);
  const intervalId = useRef(null);
  const betId =
    firstOdd.event_id ||
    Number(match?.externalId?.replace('sr:sport_event:', ''));
  const isEnded = [...MATCH_STATUS.ENDED, MATCH_STATUS.MATCH_ENDED].includes(
    match?.status
  );
  const isLive = MATCH_STATUS.LIVE.includes(match?.status);
  const isNotStarted = MATCH_STATUS.NOT_STARTED === match?.status;
  const [matchInfo, setMatchInfo] = useState({
    match,
  });

  const handleScroll = useCallback(() => {
    if (!cardRef.current) return;

    const { top } = cardRef.current.getBoundingClientRect();
    setIsFixed(top <= 96);
  }, []);

  useEffect(() => {
    if (enableFixed && isMobile) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }

    return () => {};
  }, [enableFixed, isMobile, handleScroll]);

  const getScoresData = useCallback(async ({ eventId }) => {
    intervalId.current = setInterval(async () => {
      try {
        const matchScores = await getMatchScores({
          matchId: `sr:sport_event:${eventId}`,
        });

        console.log('matchScores :>> ', matchScores);
      } catch (error) {
        clearInterval(intervalId.current);
      }
    }, 10000);
  }, []);

  const getMatchData = useCallback(async ({ eventId }) => {
    const matchData = await getMatchInfo({
      matchId: `sr:sport_event:${eventId}`,
    });

    setMatchInfo(matchData);
  }, []);

  useEffect(() => {
    if (sizeFunc(data) > 0 && isEmpty(match)) {
      getMatchData({ eventId: betId });
    }
  }, [betId, data, getMatchData, getScoresData, match]);

  const [teamHome = {}, teamAway = {}] = matchInfo?.match?.teams ?? [];
  const { date_start: dateStart, tournament_name: tournamentName } =
    firstOdd || {};

  return (
    <S.HeadToHeadCardWrapper
      ref={cardRef}
      className={`head-to-head__wrapper  ${
        enableFixed && isFixed ? 'fixed' : ''
      }`}
    >
      <div className='head-to-head__wrapper-link'>
        <Link
          target='_blank'
          prefetch={false}
          href={`https://betnacional.bet.br/event/1/1/${betId}?p=GameArena`}
        >
          <Team
            size={size}
            team={
              isEmpty(teamHome)
                ? {
                    name: firstOdd?.home,
                    logo: shieldPlaceholder,
                  }
                : teamHome
            }
          />
          <Info
            size={size}
            date={dateStart || match?.startTime}
            matchData={{
              time: null,
              round: infos?.round,
              competitionName: tournamentName,
              status: {
                isLive,
                isEnded,
                isNotStarted,
              },
              score: {
                home: {
                  match: teamHome?.score,
                  penalties: teamHome?.penaltiesScore,
                },
                away: {
                  match: teamAway?.score,
                  penalties: teamAway?.penaltiesScore,
                },
              },
            }}
          />
          <Team
            size={size}
            team={
              isEmpty(teamAway)
                ? {
                    name: firstOdd?.away,
                    logo: shieldPlaceholder,
                  }
                : teamAway
            }
          />
        </Link>
      </div>
    </S.HeadToHeadCardWrapper>
  );
};

HeadToHeadCard.propTypes = {
  isMobile: PropTypes.bool,
  enableFixed: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large']),
  data: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  match: PropTypes.shape({
    status: PropTypes.string,
    startTime: PropTypes.string,
    externalId: PropTypes.string,
  }),
  infos: PropTypes.shape({
    round: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default React.memo(HeadToHeadCard);
